import { COMMAND_NAMES } from './command-constants';

export const photo = () => {
  const command = {
    name: COMMAND_NAMES.PHOTO,
    description: ``,
    run: () => `
                  ,▄▄▓▓▓████████▓▓▓▄▄▄
              ,▄▓███▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓████▓▄▄
            ,▄███▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓███▓▄
          ╓▓████████████████████▓▓▓▓▓▓▓▓▓▓▓█████▄
        ╔████████████████████████████████████████▄
        ██████████████████████████████████████████▌
      ,▓██████╙└└└╙╙╙╙╙╙╙╙╙╙╙╙╙╙╙╚╚╚╚╩╩╩╩╩╩╩╩╩▀█████▌
    ╓██████▌░'       ,,,              ▄▄▓▓▓▄▄ !░╚███
    .███████░│'  ,▄▓▓▀▀╙╙▀▀Γ          ^╙└'   ╙▀▓▄░╠██▄
    ╫██████▒░░'╓▓╬,,,╓╓,,,,           ,▄▄▄▓▓▓▓████████▄▄▄
    ███████▓▓▓▓▓▓▓▓╝╝▀▀▀▀╝╣▓▓╬╣▓▓▓▓█▓▓▓▓▀▀╠░;;;;, │╠╠████▒
  :████████╬▓╙,≤φφφ▒▒▒▒▒▒▒▒╠╬▓╣╣█▓█╣▓▓╬╠╩╠▒▄▒▒╚╚╠▒▒╟▓╣█▌.
  :██████▓▓╬▌▒╬╩╚╠▄╣▀▀▀╣▄,""╚▓╣▓╩╙╩▓╣▓░#▓▓▓▄▄ ╙▀▄░╠╬▓▓█▒
    █████▌▒▓╬▓╬Γ▄▀,▓▀███▓ └▀µ▐╬▓▒   ╚▓╬▓█▄╟████  ╙▌░╠▓▓█▌
    ╟████▒▒╟▓▓▒α▀ ██▄█████  ╚▓╣▒     ╚▓╬██████▓⌐  .░░╫██⌐
    ████▒▒░█╬▌░  ╚█████╬▀ ,╣╢▓░.»=ⁿ=»╙╠▓▓█▓╙╙╙   ,▄▓██▀
    ╙███▒▒░╙▓▓▓╗▄▄,,,▄▄▄╗╬╬▓╬╙φ░     "░╙╩▓█████████▀╠▌╩⌐
    ≤╔, █▓▒▒░╚╬╬▓▓▓▓▓▓▓▓▓╬╩╩╙  ╠▒╓,,,╓φ▒   "╙╙╙╚╚╩╩╩╩╩╟░[
    "╙╚▒██▌▒░░└╙╙╚╚╙╙╙╙"^      ,╠╬╬╣╣╣▄▄,          '¡░╟▌[
      !░╫██▓▒░┐            ,▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄      '¡▄██░
    ! '░╟████▓▄µ        ,▄▓▓█▀▀╠╦φφ▒▄▒▒▒▄╬╠██▓▓▄▄▄▄▓▓███▌
    ' '"███████▓▓▓▄▄▄▄▓▓▓▓▀"╙╚╙╙╚╚╚╙╙""^   ╟▓▓▓█████████
      7φ╓███████████████▓▓▓⌐      ▄▄▓▓▄     ╟▓███████████
        ╟████████████████▓▓▄   ▄▓▓▓▓▓▓▓▓▄▄▓▓▓███████████
          ████████████████▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓███████████
          ╙███████████████▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓███████████▌
          ╙███████████████▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓████████████
            ╙████████████████▓▓▓▓▓▓▓▓▓▓▓▓▓▓████████████⌐
            ╙█████████████████▓▓▓▓▓▓▓▓▓▓█████████████░
              '▀█████████████████████████████████████
                ▀█████████████████████████████████╙
                  └╙▀█████████████████████████▀╙
                        ╙╙╙▀▀▀▀▀▀▀▀▀▀▀▀▀▀╙╙└
  `
  };

  return command;
};
