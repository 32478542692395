import { COMMAND_NAMES } from './command-constants';

export const music = {
  name: COMMAND_NAMES.MUSIC,
  description: ``,
  run: () => {
    return `
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒░░░░░░
░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░░░░░
░░░░░░▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒░░░░░░
░░░░░░▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒░░░░░░
░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░░░░
░░▒▒▒▒▒█▓▓▓▓▓▓▓▓▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▒▒▒▒░░
░░▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒░░
░░▒▒▒▒▒▒▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒▓▓▓▒▓▓▒▒▓▓▒▒▒▒▓▓▒▒▒▓▓▒▒▒▒▒▒▒▒▒▒░░
░░▒▒▒▒▒▒▓▓▒▒▒▓▓▒▒▒▓▒▒▒▒▒▓▓▒▓▓▓▒▓▓▒▒▓▓▒▒▒▒▓▓▒▒▒▓▓▒▒▒▓▓▒▒▒▒▒░░
░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░
░░▒▒▒░░▒▓▓▓▓▓░░░░▒▒▒▒█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▓▒▒▒░░░▒▓▓▓▓▓▒░░░▒▒░░
░░▒▒░░░▒▓▓▓▓▓░░░░▒▒▒▒█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒▒▒░░░▒▓▓▓▓▓░░░░▒▒░░
░░▒▒░░░▒▓▓▓▓▓░░░░▒▒▒▒█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒▒▒░░░▒▓▓▓▓▓▒░░░▒▒░░
░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░
░░▒▒▒▒▒▓▓▓▓▓▓▓▓▒▒▒▒▒▒██████████████████▓▒▒▒▒▒▓▓▓▓▓▓▓▓▒▒▒▒▒░░
░░▒▒▒▓██████████▓▒▒▒▒██████████████████▓▒▒▒▓███████████▓▒▒░░
░░▒▓██████████████▓▒▒██▓▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▓▒▒▓█████████████▓▒░░
░░▒█████▓▒░░▒▓█████▒▒██▒░▒▒▒▒▒▒▒▒▒▒░░▓█▓▒▓█████▒▒░▒▓█████▓░░
░░▒█████▒░░░░▒█████▓▒██▒░▒▒▒▒▒▒▒▒▒▒░░▓█▓▒█████▓░░░░░▓████▓░░
░░▒▓████▓▓▒▒▒▓█████▒▒██▓▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▓▒▓█████▓▒▒▒▓█████▓░░
░░▒▒▓████████████▓▒▒▒██████████████████▓▒▒▓█████████████▓▒░░
░░▒▒▒▓▓████████▓▓▒▒▒▒██████████████████▓▒▒▒▒▓█████████▓▒▒▒░░
░░▒▒▒▒▒▒▒▓▓▓▓▒▒▒▒▒▒▒▒██████████████████▓▒▒▒▒▒▒▒▓▓▓▓▓▒▒▒▒▒▒░░
░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒██████████████████▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
`;
  },
};
